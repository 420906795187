import React from 'react'
import { FullPageHero } from '../Sections'
import Layout from '../Layout'

import { labels } from '../../langs/langs'

import iconI from '../../images/circle-check-green.svg'

const ThankYouForSubs = ({ localeID, location }) => {
  return (
    <Layout location={location} title={labels[localeID]['THX']} localeID={localeID}>
      <FullPageHero
        localeID={localeID}
        blue={true}
        title={<img className="inline -mb-8" src={iconI} />}
      >
        <span className="text-2xl font-bold">{labels[localeID]['SUB_SUCC']}</span>
      </FullPageHero>
    </Layout>
  )
}

export default ThankYouForSubs
